import { useEffect, useRef, useState } from "react";
import "./FormComponents.css";
import { timeSlots } from "./OptionsList";
import TimeZoneDropdown from "./TimeZoneDropdown";
export default function PickTime({
  setTimeSelected,
  setTimeZoneSelected,
  setDurationSelected,
  selectedDate,
}) {
  const [timeSlot, setTimeSlot] = useState(null);
  const [duration, setDuration] = useState(0);
  const slots = timeSlots[duration];
  const timeSlotsRef = useRef(null);
  // ADDED: Utility function to parse time string and get time in milliseconds
  const parseTime = (timeStr) => {
    const [startTime] = timeStr.split(" - ");
    const [hour, minute, period] = startTime.split(/[:\s]/);

    let hours = parseInt(hour, 10);
    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    return new Date().setHours(hours, parseInt(minute, 10), 0, 0);
  };

  // ADDED: Function to check if a time slot should be disabled
  const isSlotDisabled = (slotTime) => {
    const now = new Date();
    if (isSelectedDateToday()) return parseTime(slotTime) < now.getTime();
    else if (isWeekend()) return true;
    else return false;
  };

  const isSelectedDateToday = () => {
    const today = new Date();
    return (
      selectedDate &&
      today.getFullYear() === selectedDate.getFullYear() &&
      today.getMonth() === selectedDate.getMonth() &&
      today.getDate() === selectedDate.getDate()
    );
  };
  const isWeekend = () => {
    const date = selectedDate;
    const day = date.getDay(); // 0 for Sunday, 6 for Saturday
    return day === 0 || day === 6;
  };
  const hasAvailableSlots = () => {
    if (!isSelectedDateToday() && !isWeekend()) return slots.length > 0;
    else return slots.some((slot) => !isSlotDisabled(slot));
  };
  useEffect(() => {
    const hasAvailableSlot = hasAvailableSlots();
    if (hasAvailableSlot) {
      let slotIndex = -1;
      const firstAvailableIndex = slots.findIndex(
        (slot) => !isSlotDisabled(slot)
      );
      if (isSelectedDateToday()) {
        slotIndex = firstAvailableIndex;
      } else {
        slotIndex = 0;
      }
      if (timeSlot === null && slotIndex !== -1) {
        if (isWeekend()) return;
        setTimeSelected(slots[slotIndex]);
        setTimeSlot(slotIndex + 1);
        setTimeZoneSelected("GMT+05:30");
      } else if (timeSlot !== null) {
        setTimeZoneSelected("GMT+05:30");
        setTimeSelected(slots[slotIndex]);
        setTimeSlot(slotIndex + 1);
      } else {
        console.log("78");

        setTimeSelected(
          slots[timeSlot - 1] || "No available time slots for the date selected"
        );
      }
    } else {
      setTimeZoneSelected("");
      setTimeSelected("No available time slots for the date selected");
    } // eslint-disable-next-line
  }, [duration, selectedDate]);

  useEffect(() => {
    if (timeSlotsRef.current) {
      const firstAvailableIndex = slots.findIndex(
        (slot) => !isSlotDisabled(slot)
      );
      if (firstAvailableIndex !== -1) {
        const firstAvailableElement =
          timeSlotsRef.current.children[firstAvailableIndex];
        if (firstAvailableElement) {
          firstAvailableElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }
    } // eslint-disable-next-line
  }, [duration, selectedDate]);
  const handleClick = (e, index) => {
    if (isSelectedDateToday() && isSlotDisabled(e.target.innerText)) {
      return;
    }
    if (isWeekend()) return;
    const newTimeSlot = e.target.innerText;
    setTimeSlot(index);
    setTimeSelected(newTimeSlot);
  };

  const handleDuration = (e, index) => {
    setDuration(index);
    setDurationSelected(e.target.innerText);
  };

  return (
    <div className="pick-time-wrapper">
      <div className="pick-time-header">
        <h2>Pick a time</h2>
      </div>
      <TimeZoneDropdown
        timeAvalilability={hasAvailableSlots()}
        setTimeZoneSelected={(timeZone) => {
          setTimeZoneSelected(timeZone);
        }}
      />
      <div className="time-duration-tab">
        <div
          onClick={(e) => handleDuration(e, 0)}
          className={`${duration === 0 ? "active" : ""}`}
        >
          <p>15min</p>
        </div>
        <div
          onClick={(e) => handleDuration(e, 1)}
          className={`${duration === 1 ? "active" : ""}`}
        >
          <p>30min</p>
        </div>
      </div>
      <div className="time-slots" ref={timeSlotsRef}>
        {slots.map((slot, index) => {
          // Determine if slot should be disabled
          const isDisabled = isSlotDisabled(slot) ? "time-slot-disabled" : "";

          return (
            <div
              key={index}
              onClick={(e) => handleClick(e, index + 1)}
              className={`time-slot ${
                timeSlot === index + 1 ? "time-slot-picked" : ""
              } ${isDisabled}`}
              style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
            >
              <p>{slot}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
