import React, { useEffect, useState } from "react";
import "./Steps.css";
import activeStep1 from "../../assets/active-step-1.svg";
import activeStep2 from "../../assets/active-step-2.svg";
import activeStep3 from "../../assets/active-step-3.svg";
import inactiveStep2 from "../../assets/inactive-step-2.svg";
import inactiveStep3 from "../../assets/inactive-step-3.svg";
export default function Steps({ step }) {
  const [stepImages, setStepImages] = useState({
    step1: "",
    step2: "",
    step3: "",
  });
  useEffect(() => {
    const images = {
      step1: activeStep1,
      step2: step >= 2 ? activeStep2 : inactiveStep2,
      step3: step === 3 ? activeStep3 : inactiveStep3,
    };
    setStepImages(images);
  }, [step]);
  return (
    <div className="steps-wrapper">
      <div className={`step ${step >= 1 ? "active" : ""}`}>
        <img src={stepImages.step1} alt="Step 1" />
        <div className="step-info">
          <h2>Your Information</h2>
          <p>Step 1</p>
        </div>
      </div>
      <div className="progress-bar-wrapper">
        <div className={`step-progress-bar ${step > 1 ? "active" : ""}`}></div>
      </div>
      <div className={`step ${step >= 2 ? "active" : ""}`}>
        <img src={stepImages.step2} alt="Step 2" />
        <div className="step-info">
          <h2>Personalization</h2>
          <p>Step 2</p>
        </div>
      </div>
      <div className="progress-bar-wrapper">
        <div className={`step-progress-bar ${step > 2 ? "active" : ""}`}></div>
      </div>
      <div className={`step ${step === 3 ? "active" : ""}`}>
        <img src={stepImages.step3} alt="Step 3" />
        <div className="step-info">
          <h2>Schedule Demo</h2>
          <p>Step 3</p>
        </div>
      </div>
    </div>
  );
}
