import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";

function CustomCalendar({ setDateSelected }) {
  const [date, setDate] = useState(new Date());
  const today = new Date();

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setDateSelected(newDate);
  };

  return (
    <div className="callender-page">
      <style>
        {`
 
          * {
            margin: 0;
            padding: 0;
          }
           
          .react-calendar {
            width: 380px;
            max-width: 100%;
            background-color: #fff;
            color: #222;
            border-radius: 8px;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.125em;
            border: none;
            width: 100%;
          }
          .react-calendar__navigation button {
            color: #0D5C9C;
            min-width: 44px;
            background: none;
            font-size: 20px;
            font-weight: 800;
            margin-top: 8px;
          }
          .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
          background: #97D0FF66;
          border: 1px solid #4399DF
          color: #0D5C9C;
          }
          .react-calendar__tile {
          padding: 15px 6.6667px;
          }
          .react-calendar__navigation button:enabled:hover,
          .react-calendar__navigation button:enabled:focus {
            background-color: #f8f8fa;
          }
          .react-calendar__navigation button[disabled] {
            background-color: #f0f0f0;
          }
          abbr[title] {
            text-decoration: none;
          }
          .react-calendar__tile:enabled:hover,
          .react-calendar__tile:enabled:focus {
            border-radius: 6px;
          }
            .react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury {
    color: #707070;
}
    .react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
          .react-calendar__tile--now {
            background: #6f48eb33;
            border-radius: 6px;
            font-weight: bold;
            color: #6f48eb;
          }
          .react-calendar__tile--now:enabled:hover,
          .react-calendar__tile--now:enabled:focus {
            border-radius: 6px;
            font-weight: bold;
            color: #6f48eb;
          }
          .react-calendar__tile--hasActive:enabled:hover,
          .react-calendar__tile--hasActive:enabled:focus {
            // background: #f8f8fa;
          }
          .react-calendar__tile--active {
            border-radius: 6px;
            font-weight: bold;
            color: white;
          }
          .react-calendar__tile--active:enabled:hover,
          .react-calendar__tile--active:enabled:focus {
          }
          .react-calendar--selectRange .react-calendar__tile--hover {
            background-color: #f8f8fa;
          }
          .react-calendar__tile--range {
            background: #f8f8fa;
            color: #6f48eb;
            border-radius: 0;
          }
          .react-calendar__tile--rangeStart {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            background: #6f48eb;
            color: white;
          }
          .react-calendar__tile--rangeEnd {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            background: #97D0FF66;
            color:#0D5C9C;
          }
        `}
      </style>
      <div className={`callender-wrapper `}>
        <div className={`callernder-top-wrapper`}>
          <div className={`calender-container-wrapper`}>
            <div className="calendar-container">
              <div className="top-callender-container">
                <Calendar
                  onChange={handleDateChange}
                  value={date}
                  minDate={today}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomCalendar;
