import "./FormComponents.css";

export const FormButtons = ({ children }) => {
  return <div className="buttons-wrapper">{children}</div>;
};

export const CancelButton = ({ name, onClick }) => {
  return (
    <button className="cancel-button" onClick={onClick}>
      <span>{name}</span>
    </button>
  );
};

export const SubmitButton = ({ name, boolVar = true, onClick }) => {
  return (
    <button
      className={`submit-button ${boolVar ? "" : "not-allowed"}`}
      onClick={onClick}
    >
      <span>{name}</span>
    </button>
  );
};
