import "./FormComponents.css";
export default function ProgressBar({ progress = 0 }) {
  return (
    <div
      className="progress-bar-container"
      style={{ background: progress ? "#eee" : "#fff" }}
    >
      <div className="progress-bar" style={{ width: `${progress}%` }} />
    </div>
  );
}
