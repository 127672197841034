// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-wrapper {
  background: #fff7f3;

  padding: 5rem 5rem 0;
  position: relative;
  width: 25%;
  height: auto;
  border-radius: 32px;
}

.sidebar-content {
  position: relative;
  z-index: 1;
}

.sidebar-content h1 {
  margin-top: 3rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: #3a3a3a;
}
.sidebar-content p {
  color: #3a3a3a;
}
.sidebar-image {
  margin-top: 3rem;
}
@media (max-width: 1450px) {
  .sidebar-wrapper:after {
    background-size: contain;
  }
  .sidebar-wrapper {
    width: 30%;
    padding: 5rem 3rem 0;
  }
}

@media (max-width: 768px) {
  .sidebar-wrapper {
    width: 100%;
    padding: 5rem 3rem 3rem;
  }
  .sidebar-wrapper:after {
    background: none;
  }
  .sidebar-content {
    text-align: center;
  }
  .step-info p {
    display: none;
  }
  .step-info h2 {
    font-size: 1.5rem;
  }
  .sidebar-image {
    display: none;
  }
}
@media (max-width: 576px) {
  .sidebar-content {
    text-align: left;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Book Demo Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;;EAEnB,oBAAoB;EACpB,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,WAAW;IACX,uBAAuB;EACzB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,aAAa;EACf;EACA;IACE,iBAAiB;EACnB;EACA;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".sidebar-wrapper {\n  background: #fff7f3;\n\n  padding: 5rem 5rem 0;\n  position: relative;\n  width: 25%;\n  height: auto;\n  border-radius: 32px;\n}\n\n.sidebar-content {\n  position: relative;\n  z-index: 1;\n}\n\n.sidebar-content h1 {\n  margin-top: 3rem;\n  font-size: 3rem;\n  font-weight: 700;\n  line-height: 1.2;\n  color: #3a3a3a;\n}\n.sidebar-content p {\n  color: #3a3a3a;\n}\n.sidebar-image {\n  margin-top: 3rem;\n}\n@media (max-width: 1450px) {\n  .sidebar-wrapper:after {\n    background-size: contain;\n  }\n  .sidebar-wrapper {\n    width: 30%;\n    padding: 5rem 3rem 0;\n  }\n}\n\n@media (max-width: 768px) {\n  .sidebar-wrapper {\n    width: 100%;\n    padding: 5rem 3rem 3rem;\n  }\n  .sidebar-wrapper:after {\n    background: none;\n  }\n  .sidebar-content {\n    text-align: center;\n  }\n  .step-info p {\n    display: none;\n  }\n  .step-info h2 {\n    font-size: 1.5rem;\n  }\n  .sidebar-image {\n    display: none;\n  }\n}\n@media (max-width: 576px) {\n  .sidebar-content {\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
