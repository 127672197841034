import React from "react";
import "./Forms.css";
import YourInformation from "./YourInformation";
import Personalization from "./Personalization";
import ScheduleDemo from "./ScheduleDemo";
export default function Forms({ step, setStep }) {
  return (
    <div
      className="form-wrapper"
      style={{ paddingBottom: step === 1 ? "3rem" : "0" }}
    >
      {step === 1 && <YourInformation setStep={setStep} />}
      {step === 2 && <Personalization step={step} setStep={setStep} />}
      {step === 3 && <ScheduleDemo setStep={setStep} />}
    </div>
  );
}
