import React, { useState } from "react";
import BookDemoSidebar from "../components/Book Demo Sidebar/BookDemoSidebar";
import Forms from "../components/Forms/Forms";

export default function BookDemo() {
  const [step, setStep] = useState(1);
  return (
    <div
      className="body-wrapper"
      style={{ height: step <= 2 ? "96vh" : "100%" }}
    >
      <BookDemoSidebar step={step} />
      <Forms step={step} setStep={setStep} />
    </div>
  );
}
