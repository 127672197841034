import React, { useEffect, useRef, useState } from "react";
import "./FormComponents.css";
import dropdownArrow from "../../assets/dropdown-icon.svg";
export default function TimeZoneDropdown({
  setTimeZoneSelected,
  timeAvalilability,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [timeZone, setTimeZone] = useState("GMT+05:30");
  const timezones = [
    "GMT-11:00",
    "GMT-10:00",
    "GMT-09:00",
    "GMT-08:00",
    "GMT-07:00",
    "GMT-06:00",
    "GMT-05:00",
    "GMT-04:00",
    "GMT-03:00",
    "GMT-02:00",
    "GMT-01:00",
    "GMT±00:00",
    "GMT+01:00",
    "GMT+02:00",
    "GMT+03:00",
    "GMT+04:00",
    "GMT+05:00",
    "GMT+05:30",
  ];
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    if (!timeAvalilability) {
      alert("Select a time slot");
      return;
    }
    setIsOpen(!isOpen);
  };

  const handleTimeZoneClick = (timezone) => {
    const timeZonePicked = timezone;
    setTimeZone(timeZonePicked);
    setTimeZoneSelected(timeZonePicked);

    toggleDropdown();
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <p>Select time zone</p>
      <div className="dropdown-header" onClick={toggleDropdown}>
        <span>{timeZone}</span>
        <img src={dropdownArrow} alt="Dropdown Arrow" />
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {timezones.map((timezone, index) => (
            <div
              key={index}
              className="dropdown-item"
              onClick={() => handleTimeZoneClick(timezone)}
            >
              <p>{timezone}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
