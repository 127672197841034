import React, { useState } from "react";
import "./FormComponents.css";
export default function InputBox({ handleInputAnswer }) {
  const [inputData, setInputData] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setInputData(e.target.value);
    setError(false);
  };
  const handleSubmit = () => {
    if (!inputData) {
      setError(true);
    } else handleInputAnswer(inputData);
  };
  return (
    <div className="input-box">
      <input
        type="text"
        value={inputData}
        autoComplete="off"
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit(e); // Call handleSubmit if Enter key is pressed
          }
        }}
      />

      <button className="send-button" onClick={handleSubmit}>
        <span> Send </span>
      </button>
      {error && (
        <span className="error-message" style={{ left: 0 }}>
          Please enter a specific value
        </span>
      )}
    </div>
  );
}
