import React, { useState } from "react";
import "./Forms.css";
import FormHeading from "../FormComponents/FormHeading";
import { questionsList } from "../FormComponents/OptionsList";
import ProgressBar from "../FormComponents/ProgressBar";
import Questions from "../FormComponents/Questions";

export default function Personalization({ step, setStep }) {
  const questions = questionsList;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const progress = (currentQuestionIndex / questions.length) * 100;
  return (
    <>
      <FormHeading h1="Personalization" p="Select your primary focus areas." />
      <div className="heading-inner">
        <h2>Select your primary focus areas.</h2>
      </div>
      <ProgressBar progress={progress} />
      <div className="questions-wrapper">
        <Questions
          step={step}
          setStep={setStep}
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
        />
      </div>
    </>
  );
}
